import React from 'react'
import Contact from '../../component/Contact/Contact'

const Contact_Page = () => {
  return (
    <>
      <Contact />
    </>
  )
}

export default Contact_Page
